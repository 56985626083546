/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { untilDestroyed } from '@s2a-core/ng-core';
import { RoughTimePipe } from '@s2a-core/ng-pipes';
import { AuthService, Translation } from '@s2a/core';

import { LastEvaluatedKey } from '../graphql/queries/history-item.queries';
import { onCreateHistoriesSubscription } from '../graphql/subscriptions/history-item.subscription';
import { FallbackLanguage, HistoryItem, ReadStatus, ServiceIcon, Timeline } from '../models/notification-history.model';
import { GraphQLHistoryService } from './graphQl-history.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryItemService {
  latestTimestamp = -1;
  unreadCount = 0;
  lastEvaluatedKey: LastEvaluatedKey;
  historyItems: HistoryItem[] = [];
  historyItemQueryRef: QueryRef<any, any>;
  historyItems$ = new Subject<Timeline[]>();
  newHistory$ = new Subject<number>();
  historyItemsRetrieved$ = new Subject<boolean>();
  private untilDestroyed = untilDestroyed();

  constructor(
    private authService: AuthService,
    private apolloClient: Apollo,
    private roughTimePipe: RoughTimePipe,
    private translation: Translation,
    private graphQLHistoryService: GraphQLHistoryService,
  ) {
    this.authService.isLoggedIn$.pipe(this.untilDestroyed()).subscribe((isValidSession) => {
      this.graphQLHistoryService.setupGraphClient(isValidSession);
    });
  }

  getUnseenCount(): Observable<number> {
    this.graphQLHistoryService
      .getUnseenCount()
      .pipe(this.untilDestroyed())
      .subscribe((unreadCount) => {
        this.unreadCount = unreadCount;
        this.newHistory$.next(this.unreadCount);
      });

    return this.newHistory$;
  }

  groupHistoryItem(historyItem: Timeline[]): Array<{ title: string; items: any }> {
    const timelineMap: { [key: string]: any[] } = historyItem
      .map((history) => ({
        ...history,
        key: this.roughTimePipe.transform(history.timestamp),
      }))
      .reduce(
        (previousValue, currentValue) => {
          if (previousValue[currentValue.key]) {
            previousValue[currentValue.key].push(currentValue);
          } else {
            previousValue[currentValue.key] = [currentValue];
          }

          return previousValue;
        },
        {} as { [key: string]: any[] },
      );

    return Object.keys(timelineMap).map((currentValue) => ({
      title: currentValue,
      items: timelineMap[currentValue],
    }));
  }

  timelineFromHistory(history: HistoryItem): Timeline {
    const template = JSON.parse(history.resolvedTemplate || '{}');
    const resolvedTemplateForCurrentLanguage: any = template[this.translation.getCurrentLanguage()]
      ? template[this.translation.getCurrentLanguage()]
      : template[FallbackLanguage] || {};

    return {
      ...history,
      title: resolvedTemplateForCurrentLanguage?.subject || '',
      titleLink: resolvedTemplateForCurrentLanguage?.appLink || '',
      description: resolvedTemplateForCurrentLanguage?.text || '',
      iconUrl: ServiceIcon[history.service.toUpperCase()],
    };
  }

  updateStatus(timestamp: number, readStatus: ReadStatus): void {
    this.graphQLHistoryService.updateHistory(timestamp, readStatus).subscribe();
    this.historyItems = this.historyItems.map((history) => {
      if (history.timestamp === timestamp) {
        return { ...history, readStatus };
      }

      return history;
    });
    this.historyItems$.next(this.historyItems.map((history) => this.timelineFromHistory(history)));
  }

  updateUnreadHistories(): void {
    this.graphQLHistoryService
      .bulkHistoryUpdate(ReadStatus.READ)
      .pipe(
        this.untilDestroyed(),
        map(
          (responseData) => responseData.data.bulkUpdateStatus.response.message as OperatorFunction<any, any>,
        ) as OperatorFunction<any, any>,
      )
      .subscribe();
    this.historyItems = this.historyItems.map((history) => ({
      ...history,
      readStatus: ReadStatus.READ,
    }));

    this.historyItems$.next(this.historyItems.map((history) => this.timelineFromHistory(history)));
  }

  updateUnseenHistories(): Observable<string> {
    return this.graphQLHistoryService
      .bulkHistoryUpdate(ReadStatus.UNREAD)
      .pipe(
        this.untilDestroyed(),
        map(
          (responseData) => responseData.data.bulkUpdateStatus.response.message as OperatorFunction<any, any>,
        ) as OperatorFunction<any, any>,
      ) as Observable<string>;
  }

  updateTimelineWithTranslation(): void {
    this.translation.onLangChange.pipe(this.untilDestroyed()).subscribe(() => {
      this.historyItems$.next(this.historyItems.map((history) => this.timelineFromHistory(history)));
    });
  }

  establishGetHistorySubscription(pageSize: number): void {
    this.graphQLHistoryService.client$.subscribe(() => {
      this.historyItems = [];
      const userId = this.authService.user!.userId;
      this.getAllRecordByPaging(pageSize, {
        timestamp: new Date().getTime() + 1000,
        userId,
      });
      this.subscribeHistory();
    });
    this.updateTimelineWithTranslation();
  }

  /*
   * Recursively get all pages history
   */
  getAllRecordByPaging(pageSize: number, lastEvaluatedKey?: LastEvaluatedKey): void {
    this.graphQLHistoryService.getHistory(pageSize, lastEvaluatedKey).subscribe((historyResponse: any) => {
      this.historyItems.push(...(historyResponse.history as HistoryItem[]));
      this.historyItemsRetrieved$.next(true);
      if (this.historyItems && this.historyItems.length) {
        this.latestTimestamp = this.historyItems[this.historyItems.length - 1].timestamp;
        this.historyItems$.next(this.historyItems.map((history) => this.timelineFromHistory(history)));
      }
      this.lastEvaluatedKey = historyResponse.lastEvaluatedKey;
    });
  }

  subscribeHistory(): void {
    const userId = this.authService.user?.userId;
    this.apolloClient
      .subscribe({
        query: onCreateHistoriesSubscription,
        variables: { userId },
      })
      .subscribe((responseData: any) => {
        const data: HistoryItem = responseData.data.onCreateHistoryItem;
        if (this.latestTimestamp !== data.timestamp) {
          this.latestTimestamp = data.timestamp;
          this.unreadCount = this.unreadCount + 1;
          this.newHistory$.next(this.unreadCount);
          this.historyItems.unshift(data);
          this.historyItems$.next(this.historyItems.map((history) => this.timelineFromHistory(history)));
        }
      });
  }
}
