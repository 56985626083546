<mat-expansion-panel
  class="expansion-panel"
  [expanded]="displayMode === 'expanded'"
  (opened)="(true)"
  (closed)="(false)"
>
  <mat-expansion-panel-header class="expansion-header">
    {{ title }}
  </mat-expansion-panel-header>

  <ng-content select="content" ngProjectAs="pure-panel-content"></ng-content>
</mat-expansion-panel>
