const SESSION_URL_STANDARD = 'https://sessions.share2act.io/sessions/-LVxNGx5OuSzdP1gge50/';
const SESSION_URL_SALES = 'https://demo-sessions.share2act.io/';

export const getSessionByRight = (rights: string[]) => {
  if (rights.includes('use-sessions-demo')) {
    return SESSION_URL_SALES;
  }

  return SESSION_URL_STANDARD;
};
