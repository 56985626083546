/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Environment } from '@s2a/core';

export interface CustomEnvironment extends Environment {
  subAppUrl: string;
  refreshInterval?: number;
  notificationHistoryUrl?: string;
  sentryDsn: string;
  sentryRelease: string;
  componentUrl?: string;
}

const baseDomain = location.hostname.split('.').slice(1).join('.');

const domain = baseDomain === 'localhost' ? 'share2act-dev.io' : baseDomain;

const getHistoryEndpoint = (historyDomain: string) => {
  if (historyDomain === 'share2act.io') {
    // productive
    return 'https://gy6zo4u5brghfbvh3mjmeqb7fa.appsync-api.eu-central-1.amazonaws.com/graphql';
  } else if (historyDomain === 'share2act-test.io') {
    // test
    return 'https://pjezob2j3jgj7i2v6f6zcoraji.appsync-api.eu-central-1.amazonaws.com/graphql';
  }

  return 'https://m6l7toft3zgutltikdtcclgynm.appsync-api.eu-central-1.amazonaws.com/graphql';
};

export const environment: CustomEnvironment = {
  production: domain === 'share2act.io',
  apiUrl: `https://api.${domain}/v1/`,
  iconUrl: `https://icons.${domain}/v2/`,
  imageUrl: `https://assets.${domain}/v12/images/`,
  styleUrl: `https://assets.${domain}/v12/styles/`,
  translationUrl: `https://translations.${domain}/v1/`,
  domainUrl: `https://my.${domain}/`,
  subAppUrl: `https://app.${domain}/`,
  refreshInterval: 60 * 1000, // 1 Min
  notificationHistoryUrl: getHistoryEndpoint(domain),
  sentryDsn: 'https://6e5174475303405c83b37a42b53b8162@sentry.tools.krones.digital/63',
  sentryRelease: 'xxxSENTRY_RELEASExxx' /* <-- will be set at build time */,
  componentUrl: `https://components.${domain}/`,
};
