/* eslint-disable @typescript-eslint/naming-convention */
export const MockWindow = {
  location: {
    _href: '',
    set href(url: string) {
      this._href = url;
    },
    get href(): string {
      return this._href;
    },
    _pathname: '/',
    set pathname(url: string) {
      this._pathname = url;
    },
    get pathname(): string {
      return this._pathname;
    },
    _search: '',
    set search(url: string) {
      this._search = url;
    },
    get search(): string {
      return this._search;
    },
  },
};
