import { JwtHelperService } from '@auth0/angular-jwt';
import { combineLatest, interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { S2AHttpClient } from '@s2a-core/ng-core';
import { AuthService, LOCALSTORAGE_AUTH_TOKEN, User } from '@s2a/core';

import { SessionService } from '~ng-shell';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShellAuthenticationService {
  public verifyInterval$: Observable<number>;

  constructor(
    private http: S2AHttpClient,
    private authService: AuthService,
    private sessionService: SessionService,
  ) {
    this.verifyInterval$ = interval(environment.refreshInterval);
  }

  refreshRights(): Observable<any> {
    return new Observable((observer) => {
      void (async () => {
        try {
          const jwtHelperService = new JwtHelperService();
          const token = localStorage.getItem(LOCALSTORAGE_AUTH_TOKEN) as string;
          let userId = jwtHelperService.decodeToken(token)['custom:userId'] as string;
          let user = await this.fetchUserWithRights(userId).toPromise();
          observer.next(user);
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          this.verifyInterval$.subscribe(async () => {
            if (!userId) {
              userId = jwtHelperService.decodeToken(token as string)['custom:userId'] as string;
            }
            user = await this.fetchUserWithRights(userId).toPromise();
            observer.next(user);
          });
        } catch (e) {
          observer.error(e);
        }
      })();
    });
  }

  fetchUserWithRights(userId: string): Observable<any> {
    return combineLatest([this.fetchUserInfo(userId), this.http.get<string[]>('users/me/global-rights')]).pipe(
      map(([user, globalRights]) => {
        this.sessionService.setUserInformation(user);
        this.authService.updateGlobalRights(globalRights);

        return { ...user, globalRights };
      }),
    );
  }

  /**
   * The function is used to fetch the user Info
   *
   * @param userId User ID
   * @returns Observable<User>
   */
  fetchUserInfo(userId: string): Observable<User> {
    return this.http.get<User>(`users/${userId}`);
  }
}
