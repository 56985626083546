/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from '../../environments/environment';

export const ServiceIcon = {
  EQUIPMENT: `${environment.iconUrl}equipment.svg`,
  IAM: `${environment.iconUrl}user-management.svg`,
  PERFORMANCE: `${environment.iconUrl}performance.svg`,
  TASKS: `${environment.iconUrl}task-service.svg`,
  WATCHDOG: `${environment.iconUrl}watchdog.svg`,
};

export const ServiceFilterTranslation = [
  { key: 'equipment', value: 'apps.equipment' },
  { key: 'iam', value: 'apps.user-management' },
  { key: 'watchdog', value: 'apps.v2-watchdog' },
  { key: 'tasks', value: 'apps.task-service' },
  { key: 'performance', value: 'apps.performance' },
];

export const DateOptions: Array<{ key: DateOptions; value: string }> = [
  { key: 'yesterday', value: 'global.time_labels.yesterday' },
  { key: 'this_week', value: 'global.time_labels.this_week' }, // new
  { key: 'last_week', value: 'global.time_labels.last_week' },
  { key: 'this_month', value: 'global.time_labels.this_month' }, // new
  { key: 'last_month', value: 'global.time_labels.last_month' },
  { key: 'custom', value: 'global.time_labels.custom' },
];

export type DateOptions = 'yesterday' | 'this_week' | 'last_week' | 'this_month' | 'last_month' | 'custom';
export interface FilterOptions {
  services?: string[];
  date?: {
    identifier: DateOptions;
    from: number;
    to: number;
  };
}

export const FallbackLanguage = 'en';

export const HistoryPageSize = 10;

// Value needs to be uppercase because of appsync enum schema
export enum ReadStatus {
  UNREAD = 'UNREAD',
  UNSEEN = 'UNSEEN',
  READ = 'READ',
  ALL = 'ALL',
}

export interface TimelineGroup {
  title: string;
  items: Timeline[];
}

export interface Timeline {
  account: string;
  userId: string;
  readStatus: ReadStatus;
  service: string;
  action: string;
  timestamp: number;
  expiration: number;
  title: string;
  description: string;
  iconUrl: string;
  titleLink: string;
}

export interface TimelineEvent {
  title: string;
  description: string;
  iconUrl: string;
  timestamp: number;
  readStatus: ReadStatus;
  service: string;
  appLink?: string;
}

export interface HistoryItem {
  account: string;
  userId: string;
  readStatus: ReadStatus;
  service: string;
  action: string;
  metadata: string;
  resolvedTemplate: string;
  timestamp: number;
  expiration: number;
}
