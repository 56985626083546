/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';

import { Environment, LanguageKey } from '@s2a/core';

@Injectable()
@Component({
  selector: 's2a-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  @Output() languageChanged = new EventEmitter();

  @Input() selectedLanguageKey = LanguageKey.EN;

  title = 'components.language_selection.title';
  languages: any[] = [];

  constructor(@Inject(Environment) private environment: Environment) {
    this.languages = [
      {
        key: LanguageKey.EN,
        description: 'English',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.EN}.png`,
      },
      {
        key: LanguageKey.DE,
        description: 'Deutsch',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.DE}.png`,
      },
      {
        key: LanguageKey.RU,
        description: 'Pусский',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.RU}.png`,
      },
      {
        key: LanguageKey.ES,
        description: 'Español',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.ES}.png`,
      },
      {
        key: LanguageKey.FR,
        description: 'Français',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.FR}.png`,
      },
      {
        key: LanguageKey.PT,
        description: 'Português',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.PT}.png`,
      },
      {
        key: LanguageKey.IT,
        description: 'Italiano',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.IT}.png`,
      },
      {
        key: LanguageKey.PL,
        description: 'Polski',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.PL}.png`,
      },
    ];
    // Disable Hungarian language in production
    if (environment.production) {
      this.languages.push({
        key: LanguageKey.HU,
        description: 'Hungarian',
        imageUrl: `${this.environment.imageUrl}img/flags/${LanguageKey.HU}.png`,
      });
    }
  }

  onClick(key: LanguageKey, index: number): void {
    this.selectedLanguageKey = key;
    const event = new CustomEvent('language-selected-event', {
      detail: {
        languageKey: this.selectedLanguageKey,
        description: this.languages[index].description,
      },
      bubbles: true,
      composed: true, // allows bubbling across the boundary between shadow DOM and regular DOM
    });
    document.dispatchEvent(event);
  }
}
