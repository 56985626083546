/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable } from '@angular/core';

import { AssetLoaderService, Environment } from '@s2a/core';

export enum ComponentMap {
  'ChangePassword' = 'ChangePassword',
  'BaseReference' = 'BaseReference',
  'DateRangePicker' = 'DateRangePicker',
  'SupportRequest' = 'SupportRequest',
  'Button' = 'Button',
}

@Injectable({
  providedIn: 'root',
})
export class ComponentLoaderService {
  componentState: {
    [key: string]: {
      url: string;
      loaded?: boolean;
    };
  };
  constructor(
    private assetLoaderService: AssetLoaderService,
    @Inject(Environment) private environment: any,
  ) {
    this.componentState = {
      [ComponentMap.ChangePassword]: {
        url: `${this.environment.componentUrl}change-password-wc/v7/main.js`,
      },
      [ComponentMap.BaseReference]: {
        url: `${this.environment.componentUrl}base-reference/v6/main.js`,
      },
      [ComponentMap.DateRangePicker]: {
        url: `${this.environment.componentUrl}date-range-picker/v6/main.js`,
      },
      [ComponentMap.SupportRequest]: {
        url: `${this.environment.componentUrl}support-request/v6/main.js`,
      },
      [ComponentMap.Button]: {
        url: `${this.environment.componentUrl}button/v6/main.js`,
      },
    };
  }

  loadComponent(keys: string[]): void {
    const loadComponent: string[] = [];
    keys.forEach((key) => {
      if (this.componentState[key] && !this.componentState[key].loaded) {
        loadComponent.push(this.componentState[key].url);
        this.componentState[key].loaded = true;
      }
    });
    if (loadComponent.length) {
      this.assetLoaderService.loadJsScript(loadComponent);
    }
  }
}
