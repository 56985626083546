/* eslint-disable class-methods-use-this */
import { iif, interval, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { S2AHttpClient } from '@s2a-core/ng-core';

@Injectable({
  providedIn: 'root',
})
export class HeartBeatService {
  constructor(
    private http: S2AHttpClient,
    private route: Router,
  ) {}

  heartBeat(): Observable<any> {
    const heartBeatCheckInterval = interval(1000);

    return heartBeatCheckInterval.pipe(
      mergeMap(() => iif(() => this.shouldSendHeartBeat(), this.postHeartBeat(), of(null))),
    );
  }

  /**
   * The function is used to check if the call topost heart beat can be made or not
   *
   * @returns Boolean
   */
  private shouldSendHeartBeat(): boolean {
    const currentTime = Date.now();
    const heartBeatTime = localStorage.getItem('LOCALSTORAGE_HEARTBEAT_TIMESTAMP');
    const flag = !heartBeatTime || currentTime - parseInt(heartBeatTime, 10) > 60 * 1000;

    if (flag) {
      localStorage.setItem('LOCALSTORAGE_HEARTBEAT_TIMESTAMP', currentTime.toString());
    }

    return flag;
  }

  /**
   *The function is used to post heartbeat api call
   *
   * @returns Observable<void>
   */
  private postHeartBeat(): Observable<void> {
    let activeService = this.route.url.split('/')[1];
    if (activeService.indexOf('?') >= 0) {
      activeService = activeService.substring(0, activeService.indexOf('?'));
    }
    const path = this.route.url;

    return this.http.post<void>('uaa/heartbeat', { service: activeService, path });
  }
}
